<template>
    <div v-if="$myCity">
        <div v-if="$myCity.celat_active">
            <div v-if="this.$celat_list[this.count]">
                <div class="user_help_box" v-if="direction === 'horizontal'">
                    <div v-if="this.$celat_list[this.count] && this.$celat_list[this.count].poster_id">
                        <router-link :to="{ name: 'Post', params: { id: this.$celat_list[this.count].poster_id } }">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <img :src="this.$celat_list[this.count].horizontal" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <div class="you_title">{{ this.$celat_list[this.count].name }}</div>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="this.$celat_list[this.count] && this.$celat_list[this.count].org_id">
                        <router-link :to="{ name: 'OrganisationGet', params: { id: this.$celat_list[this.count].org_id } }">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <img :src="this.$celat_list[this.count].horizontal" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <div class="you_title">{{ this.$celat_list[this.count].name }}</div>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="this.$celat_list[this.count] && this.$celat_list[this.count].shares_id">
                        <router-link :to="{ name: 'Shares', params: { id: this.$celat_list[this.count].shares_id } }">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <img :src="this.$celat_list[this.count].horizontal" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <div class="you_title">{{ this.$celat_list[this.count].name }}</div>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="this.$celat_list[this.count] && this.$celat_list[this.count].url">
                        <a :href="this.$celat_list[this.count].url" rel="nofollow" target="_blank">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <img :src="this.$celat_list[this.count].horizontal" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <div class="you_title">{{ this.$celat_list[this.count].name }}</div>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </a>
                    </div>
                </div>
                <div no-body class="you_container you_card" v-if="direction === 'square'">
                    <b-row no-gutters>
                        <img :src="this.$celat_list[this.count].square" />
                    </b-row>
                </div>
                <div no-body class="you_card" v-if="direction === 'left'">
                    <b-row no-gutters>
                        <div v-if="this.$celat_list[this.count].poster_id">
                            <router-link :to="{ name: 'Post', params: { id: this.$celat_list[this.count].poster_id } }">
                                <img :src="this.$celat_list[this.count].square" />
                            </router-link>
                        </div>
                        <div v-else-if="this.$celat_list[this.count].org_id">
                            <router-link :to="{ name: 'OrganisationGet', params: { id: this.$celat_list[this.count].org_id } }">
                                <img :src="this.$celat_list[this.count].square" />
                            </router-link>
                        </div>
                        <div v-else-if="this.$celat_list[this.count].shares_id">
                            <router-link :to="{ name: 'Shares', params: { id: this.$celat_list[this.count].shares_id } }">
                                <jmg :src="this.$celat_list[this.count].square" />
                            </router-link>
                        </div>
                        <div v-else-if="this.$celat_list[this.count].url">
                            <a :href="this.$celat_list[this.count].url" rel="nofollow" target="_blank">
                                <img :src="this.$celat_list[this.count].square" />
                            </a>
                        </div>
                    </b-row>
                </div>
            </div>
        </div>
        <div :id="`yandex_rtb_R-A-274541-6-${count + 1}`" v-else></div>
        <!-- <div v-else-if="this.$celat_list.length === 0 &&  this.stop && !this.stop.indexOf(this.$myCity.name_en) != -1">
            <b-card no-body class="you_container you_card overflow-hidden" v-if="direction === 'horizontal' || direction === 'left'">
                <div :id="'yandex_rtb_R-A-274541-' + this.yandex_id"></div>
            </b-card>
        </div> -->
    </div>
</template>
<script>
/* eslint-disable */
import stopList from "./stopList.json";
export default {
    name: "AdsPlaginNew",
    props: {
        direction: String,
    },
    data() {
        return {
            count: 0,
            stop: null,
        };
    },
    methods: {
        ads_api: function () {
            this.Preloader = null;
            let url = this.$config.api_url + this.$route.params.city + "/Celat.getList";
            this.$http
                .get(url, {
                    params: {
                        limit: 10,
                    },
                })
                .then((response) => {
                    this.$ads_list = this.$ads_list.concat(response.data.response);
                    if (this.$ads_list.length === 0) {
                        this.$stopCelat = true;
                    }
                });
        },
    },
    mounted() {
        this.count = this.$ads_count;
        this.$ads_count++;
        if (this.$myCity && !this.$myCity.celat_active) {
            window.yaContextCb.push(() => {
                Ya.Context.AdvManager.render({
                    renderTo: `yandex_rtb_R-A-274541-6-${this.count + 1}`,
                    blockId: "R-A-274541-6",
                });
            });
        }
        /*if (this.$celat_list.length <= this.$ads_count) {

            await this.$celatGetList();

        }*/
        /*this.stop = stopList.stopList;
        if (this.$celat && !this.$stopCelat) {
            if (this.$ads_count === 0 || this.$ads_count !== 0 && this.$ads_count !== 1 && this.$ads_list.length < this.$ads_count) {

                this.ads_api();

            }
            this.count = this.$ads_count;

            this.$ads_count++;

        } */
        /*else {
                    if (!this.stop.indexOf(this.$myCity.name_en) != -1) {
                        this.yandex_id = this.$yandex_id;
                        this.yandex_api();
                        this.$yandex_id++;
                    }
                }*/
    },
    watch: {
        "$route.path": function () {
            /*if (this.$myCity.celat_active && !this.$stopCelat) {
                this.$ads_count = 0;
                this.$ads_list = [];
                this.ads_api();
            }*/
            /*this.$yandex_id = 1;
            this.yandex_api();*/
        },
    },
};
</script>
<style scoped>
@media only screen and (max-width: 767px) {
    .you_body {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
    }

    .you_body .marks {
        position: relative;
        align-self: flex-end;
    }

    .you_body .you_title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
    }
}

a {
    color: #282828;
}

a:hover {
    color: #282828;
    text-decoration: none;
}

.you_card img {
    border-radius: 12px;
}

.you_card {
    margin-bottom: 10px;
    cursor: pointer;
}

.you_body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 0;
}

.you_body:hover {
    background: rgba(74, 118, 168, 0.1);
}

.you_title {
    color: #282828;
    font-size: 20px;
}

.marks {
    color: #a6a6a6;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 10px;
    border: 1px solid rgba(166, 166, 166, 0.5);
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 12px;
    border-bottom-width: 0;
    border-right-width: 0;
}

.user_help_box {
    background: #fff !important;
    border-radius: 12px !important;
    margin-bottom: 20px !important;
}

.user_help_box img {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

[id^=yandex_rtb] {
    margin-bottom: 20px;
}
</style>
